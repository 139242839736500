<template>
  <v-card class="mb-6 mt-3" elevation="0" outlined>
    <containerTitle title="Request Packet"></containerTitle>
    <v-card-text>
      <div v-for="(info, ip, index) in localValue" :key="index">
        <v-row no-gutters>
          <primaryTextfield
            :fieldAttrInput="{
              class: 'required ipAddress IPV4IPV6Domain',
              id: 'ip_address_' + index,
            }"
            label="IP-Address"
            :value="ip"
            @change="updateIp(ip, ...arguments)"
            :templateContent="result"
          ></primaryTextfield>
          <div class="mt-2 mr-1 ml-2">
            <trash @click="removeInterface(ip)" :result="result"></trash>
          </div>
        </v-row>
        <v-card elevation="0" outlined class="mb-2">
          <v-tabs
            v-model="tabs[index]"
            background-color="primary"
            dark
            @change="checkTabs(index, ...arguments)"
          >
            <v-tab
              v-for="item in Object.keys(info)"
              :key="item"
              :id="'activeProbe_' + index + '_tab_' + item"
            >
              {{ item }}
              <v-icon
                @click.stop="removeProtocol(ip, item)"
                left
                top
                size="15"
                class="closeicon"
                :disabled="disabled"
                >mdi-close</v-icon
              >
            </v-tab>
            <v-layout v-if="getRemainingProtocols(ip).length > 0" class="mt-1">
              <v-menu v-model="showMenu[index]">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :id="'addProtocol_' + index"
                  >
                    <slot>
                      <v-icon>mdi-plus-box</v-icon>
                    </slot>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(subItem, i) in getRemainingProtocols(ip)"
                    :key="subItem"
                    @click="addProrocol(ip, subItem, index)"
                  >
                    <!-- <v-list-item-content>
                      <v-list-item-title>{{ subItem }}</v-list-item-title>
                    </v-list-item-content> -->

                    <v-list-item-content :id="'protocol_' + index + '_' + i">
                      <v-list-item-title>{{ subItem }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-layout>
          </v-tabs>

          <v-tabs-items v-model="currentTabs[index]">
            <v-tab-item v-for="(protocolInfo, item, i) in info" :key="item">
              <v-card flat :id="'activeProbe_' + index + '_body_' + item">
                <v-card-text>
                  <v-form
                    :ref="'activeProbsFrom_' + index + '_' + i"
                    v-model="tabFormValidation"
                  >
                    <lineFieldsArray
                      :fields="getFields(item, protocolInfo)"
                      :value="getValueArray(protocolInfo)"
                      @line-field-input="checkInput(ip, item, ...arguments)"
                      :headerTitle="false"
                      :addable="true"
                      addButtTitle="Add"
                      :templateContent="result"
                      :isValid="isValid"
                      :linePrefix="'protocol_port_' + index + '_' + i"
                    ></lineFieldsArray>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
      <primaryButton
        type="button"
        @click="addInterface()"
        label="Add Interface"
        icon="mdi-plus"
        color="primary"
        :disabled="disabled || !isValid"
        :attributesArray="{ id: 'add_interface' }"
      ></primaryButton>
      <input type="hidden" :value="JSON.stringify(localValue)" :name="field" />
    </v-card-text>
  </v-card>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import containerTitle from "@/commonComponents/containerTitle.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
import trash from "@/commonComponents/dynamic/trash.vue";
import lineButtons from "@/cfg/lineButtons.json";
export default {
  props: ["fieldAttributes", "result", "field", "value", "isValid"],
  data() {
    return {
      tabs: {},
      currentTabs: {},
      protocolsArray: this.result.additional.protocolsArray,
      showMenu: {},
      tabFormValidation: false,
    };
  },
  components: {
    containerTitle,
    lineFieldsArray,
    primaryTextfield,
    primaryButton,
    trash,
  },
  computed: {
    localValue() {
      let value = {};
      if (typeof this.value == "object" && !Array.isArray(this.value)) {
        value = this.value;
      }
      return value;
    },
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
  },
  watch: {
    tabFormValidation(isValid) {
      this.$emit("changeIsValid", isValid);
    },
    isValid(isValid) {
      console.log(isValid);
      if (isValid) {
        Object.keys(this.localValue).some((value, index) => {
          let oldVal = this.currentTabs[index];

          if (
            typeof this.$refs["activeProbsFrom_" + index + "_" + oldVal] !=
              "undefined" &&
            this.$refs["activeProbsFrom_" + index + "_" + oldVal][0] !=
              "undefined"
          ) {
            let form = this.$refs["activeProbsFrom_" + index + "_" + oldVal][0];
            let valid = form.validate();
            if (!valid) {
              this.$emit("changeIsValid", valid);
            }
            return !valid;
          }
        });
      }
    },
  },
  methods: {
    checkTabs(index, newVal) {
      console.log("checkTabs", index, newVal, this.$refs);
      let oldVal = this.currentTabs[index];
      // if (typeof oldVal == "undefined") {
      //   oldVal = 0;
      // }
      if (
        typeof this.$refs["activeProbsFrom_" + index + "_" + oldVal] !=
          "undefined" &&
        this.$refs["activeProbsFrom_" + index + "_" + oldVal][0] != "undefined"
      ) {
        let form = this.$refs["activeProbsFrom_" + index + "_" + oldVal][0];
        let isValid = form.validate();
        if (!isValid) {
          this.$nextTick(function () {
            this.tabs[index] = oldVal;
          });
        } else {
          this.currentTabs[index] = newVal;
        }
      } else {
        this.currentTabs[index] = newVal;
      }
    },
    removeInterface(ip) {
      this.$delete(this.localValue, ip);
    },
    addInterface() {
      this.$set(this.localValue, "", {});

      this.$emit("input", this.localValue);
    },
    updateIp(old_key, new_key) {
      let renameProp = (oldProp, newProp, { [oldProp]: old, ...others }) => {
        // console.log(old);
        return {
          [newProp]: old,
          ...others,
        };
      };
      let newVal = renameProp(old_key, new_key, this.localValue);
      //  this.$delete(this.localValue, old_key);
      //  console.log(this.localValue);
      this.$emit("input", newVal);
    },

    removeProtocol(ip, item) {
      this.$delete(this.localValue[ip], item);
      this.$emit("input", this.localValue);
    },
    addProrocol(ip, protocol, index) {
      if (
        typeof this.localValue[ip] != "object" ||
        Array.isArray(this.localValue[ip])
      ) {
        this.$set(this.localValue, ip, {});
      }
      this.$set(this.localValue[ip], protocol, {});
      this.$emit("input", this.localValue);
      this.$set(this.tabs, index, Object.keys(this.localValue[ip]).length + 1);
    },
    getRemainingProtocols(ip) {
      let allProtocols = Object.keys(
        this.result["additional"]["protocolsArray"]
      );
      let used = Object.keys(this.localValue[ip]);
      used.forEach((el) => {
        allProtocols = allProtocols.filter((item) => item !== el);
      });
      return allProtocols;
    },
    activateMenu() {
      // console.log("clicked");
    },
    getValueArray(protocolInfo) {
      let value = [];
      // console.log(protocolInfo);
      for (let [protocol, info] of Object.entries(protocolInfo)) {
        info.forEach((port) => {
          value.push({ protocol: protocol, port: port });
        });
      }
      //  console.log(value);
      return value;
    },
    checkInput(ip, item, value) {
      let newVal = {};
      value.forEach((element) => {
        //console.log(item, value, element);
        if (typeof newVal[element.protocol] == "undefined") {
          this.$set(newVal, element.protocol, []);
        }
        newVal[element.protocol].push(element.port);
      });
      if (Array.isArray(this.localValue[ip])) {
        this.$set(this.localValue, ip, {});
      }
      this.$set(this.localValue[ip], item, newVal);
      this.$emit("input", this.localValue);
    },
    getFields(item) {
      let fields = {
        protocol: {
          type: "combobox",
          associatedOption: this.protocolsArray[item],
          name: "Protocol",
          fieldAttrInput: {
            class: "required",
          },
        },
        port: {
          type: "text",
          fieldAttrInput: {
            class: "required number",
            maxlength: "5",
            max: "65535",
          },
          name: "Port",
        },

        remove: lineButtons.remove,
      };
      return fields;
    },
  },
};
</script>
<style scoped>
.closeicon {
  top: 0;
}
</style>